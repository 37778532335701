'use client';
import { createContext, useContext, useState } from 'react';

import type { PropsWithChildren } from 'react';
export interface TProductErrorContext {
  message: string | undefined;
  setMessage: (message: string) => void;
}

export const ProductErrorContext = createContext<TProductErrorContext>({ message: undefined, setMessage: () => {} });

export const useProductError = () => {
  const context = useContext(ProductErrorContext);
  if (!context) {
    throw new Error('useProductError must be used within a ProductErrorProvider');
  }

  return context;
};

export const ProductErrorProvider = ({ children }: PropsWithChildren) => {
  const [message, setMessage] = useState<string | undefined>(undefined);

  return <ProductErrorContext.Provider value={{ message, setMessage }}>{children}</ProductErrorContext.Provider>;
};
