'use client';

import { useLoadScript } from '@react-google-maps/api';
import { ComboBox } from '@ui/components/form/combo-box';
import { debounce } from 'lodash';
import { GMAP_API_KEY, SCRIPT_LIBRARIES } from 'packages/constants/src';
import { memo, useState, useCallback, useMemo } from 'react';

import type { Libraries } from '@react-google-maps/api';
import type { Theme, AddressSuggestion } from 'packages/types/src';

type SearchBoxProps = {
  theme?: Theme.Props;
  processSelectedItem: (selectedItem: AddressSuggestion) => void;
  error?: boolean;
  errorMessage?: string;
  onInputValueChange?: (inputValue: string) => void;
  onClearClicked?: () => void;
  addressSearch: (addressForSearch: string) => Promise<AddressSuggestion[]>;
};

const Component = ({
  theme = 'dawn',
  processSelectedItem,
  error,
  onInputValueChange,
  onClearClicked,
  addressSearch,
}: SearchBoxProps) => {
  const [addressSuggestions, setAddressSuggestions] = useState<AddressSuggestion[]>([]);
  const [loading, setLoading] = useState(false);

  const loadScriptOptions = useMemo(
    () => ({
      googleMapsApiKey: GMAP_API_KEY,
      libraries: SCRIPT_LIBRARIES as Libraries,
    }),
    []
  );

  const { isLoaded: isGmapApiLoaded } = useLoadScript(loadScriptOptions);
  const gmapComponentsLoaded = isGmapApiLoaded;

  const debouncedAddressSearch = useCallback(
    debounce(async (addressForSearch: string) => {
      if (!gmapComponentsLoaded) {
        setAddressSuggestions([{ label: 'Loading...', subLabel: '', globalAddressKey: '' }]);
      }
      setLoading(true);
      const suggestions = await addressSearch(addressForSearch);
      setAddressSuggestions(suggestions);
      setLoading(false);
    }, 500),
    [gmapComponentsLoaded]
  );

  return (
    <ComboBox
      error={error}
      icon="search"
      itemIcon="location_on"
      items={addressSuggestions}
      loading={loading || !gmapComponentsLoaded}
      onClearClicked={() => {
        setAddressSuggestions([]);
        setLoading(false);

        onClearClicked?.();
      }}
      onInputValueChange={(inputValue) => {
        const addressForSearch = inputValue as string;

        onInputValueChange?.(addressForSearch);

        if (addressForSearch.length <= 2) return;
        debouncedAddressSearch(addressForSearch);
      }}
      onSelectedItemChange={async (selectedItem) => {
        setLoading(true);
        await processSelectedItem(selectedItem as AddressSuggestion);
        setLoading(false);
      }}
      placeholder={gmapComponentsLoaded ? 'Start typing your address' : 'Look up Loading...'}
      theme={theme}
    />
  );
};

export const AddressSearchBox = memo(Component);
