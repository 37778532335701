enum EXP_STATE {
  TAS = 'TAS',
}

enum EXPERIAN_API_ERROR {
  OOR = 'Out of region',
}

const API_BASE_URL = process.env.EXPERIAN_API_URL;
const API_KEY = process.env.EXPERIAN_API_TOKEN;

export { EXP_STATE, API_BASE_URL, API_KEY, EXPERIAN_API_ERROR };
