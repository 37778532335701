'use client';
import { Icon } from '@solstice/ui/src/components/icon';
import { duskTheme } from '@style-system/theme.css';
import { Button } from '@ui/components/button';
import { Flex } from '@ui/components/flex';
import { PromoInput } from '@ui/components/promo-input';
import { Text } from '@ui/components/text';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useRouter, useSearchParams } from 'next/navigation';
import { EXPERIAN_API_ERROR, EXP_STATE } from 'packages/constants/src';
import { fetchDPIDByGlobalAddressKey } from 'packages/lib/src/experian/fetch';
import { fetchExpAddressSuggestionsAndGlobalAddressKeysByAddress } from 'packages/lib/src/experian/post';
import { useEffect, useRef, useState } from 'react';

import { useProductError } from '~/components/products/context';

import { AddressSearchBox } from '../address-search';

import { buttonSpacing, errorColor, wrapper, textBox, errorBox } from './address-lookup.css';
import { separateAddress } from './helper';

import type { AddressFormatSchema, AddressSuggestion } from '@solstice/types';
import type { AddressLookupComponentProps } from 'packages/types/src/sanity/blocks/hero-header';

type ErrorProcessing = {
  isError: true;
  message: string;
};

type SuccessProcessing = {
  isError: false;
  address: AddressFormatSchema['address'];
  dpid: string;
  delivery_type: string;
};

type SuccessFormProcessing = {
  isError: false;
  param: string;
};

export const AddressLookupComponent = ({ home }: AddressLookupComponentProps) => {
  const [dpidFromDropdown, setDpidFromDropdwon] = useState('');
  const [addressObj, setAddressObj] = useState({});
  const [isBusiness, setIsBusiness] = useState(false);
  const [error, setError] = useState({ isError: false, message: '' });
  const productErrorContext = useProductError();
  const [address, setAddress] = useState('');
  const [formProcessing, setFormProcessing] = useState(false);
  const inputPromoRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();

  const searchParams = useSearchParams();
  const promo = searchParams.get('promo');
  const [isChecked, setIsChecked] = useState(!!promo);
  const clearErrors = () => {
    setError({ isError: false, message: '' });
  };

  const onClearValue = () => {
    if (inputPromoRef && inputPromoRef.current) {
      inputPromoRef.current.value = '';
      clearErrors;
    }
  };

  useEffect(() => {
    if (!isChecked) {
      clearErrors();
    }
  }, [isChecked]);

  useEffect(() => {
    if (productErrorContext.message && !isEmpty(productErrorContext.message)) {
      setError({ isError: true, message: productErrorContext.message });
    }
  }, [productErrorContext.message]);

  const getQueryParams = async (): Promise<ErrorProcessing | SuccessFormProcessing> => {
    const promoValue = inputPromoRef.current?.value;
    if (!promoValue && isChecked)
      return { isError: true, message: 'Please enter a promo code or deselect the checkbox to continue' };

    let dpidFromManualEntry = '';

    if (!dpidFromDropdown) {
      if (!address) return { isError: true, message: 'Please enter a valid address to continue' };
      const searchedAddresses = await addressSearch(address);

      if (searchedAddresses[0].globalAddressKey === '')
        return {
          isError: true,
          message:
            "We couldn't find a matching record with the address you entered. Please type your address and select the addresses in the dropdown for accuracy",
        };
      const res = await processSelectedItem(searchedAddresses[0]);
      if (res.isError) return { isError: true, message: res.message };
      dpidFromManualEntry = res.dpid;
      setAddressObj(res.address);
      if (res.delivery_type?.toLowerCase() == 'mixed' || res.delivery_type?.toLowerCase() == 'business') {
        setIsBusiness(true);
      }
    }

    const dpidToBeUsed = dpidFromManualEntry || dpidFromDropdown;

    const queryParams = new URLSearchParams({
      dpid: dpidToBeUsed,
      address,
      promo: promoValue || '',
      addressDetail: JSON.stringify(addressObj),
    });
    if (isBusiness) queryParams.append('business', 'true');
    if (home) {
      return { isError: false, param: `product-lookup?${queryParams.toString()}#product-result` };
    }
    return { isError: false, param: `?${queryParams.toString()}` };
  };

  const processSelectedItem = async (selectedItem: AddressSuggestion): Promise<SuccessProcessing | ErrorProcessing> => {
    const processError = (
      msg = 'We cannot verify your address, please try again or contact us for help'
    ): ErrorProcessing => {
      return { isError: true, message: msg };
    };
    const { globalAddressKey } = selectedItem as AddressSuggestion;
    if (!globalAddressKey) {
      return processError();
    }
    const res = await fetchDPIDByGlobalAddressKey(globalAddressKey);
    if (!res.success) {
      switch (res.message) {
        case EXPERIAN_API_ERROR.OOR: {
          const regions = Object.values(EXP_STATE).join(' or ');
          return processError(`The address you entered is out of ${regions}, please try again`);
        }
        default:
          return processError();
      }
    }
    return { isError: false, dpid: res.dpid, delivery_type: res.delivery_type, address: res.address };
  };

  const addressSearch = async (addressForSearch: string): Promise<AddressSuggestion[]> => {
    if (!addressForSearch) return [{ label: 'No results found', subLabel: '', globalAddressKey: '' }];
    const noResults = [{ label: 'No results found', subLabel: '', globalAddressKey: '' }];

    const resp = await fetchExpAddressSuggestionsAndGlobalAddressKeysByAddress(addressForSearch, 3);
    if (!resp.success) {
      console.warn('experian error ', resp.message);
      return [
        {
          label: 'Your search has timed out.',
          subLabel: 'Try again or call us on 1800 750 750',
          globalAddressKey: '',
        },
      ];
    }
    switch (resp.confidence) {
      case 'No matches':
        return noResults;
      case 'Too many matches':
      case 'Incomplete address':
      case 'Insufficient search terms':
        return [];
      default:
        break;
    }
    if (!resp.suggestions) {
      return noResults;
    }

    const suggestionsParsedByGMapPromises = resp.suggestions.map(async (suggestion): Promise<AddressSuggestion> => {
      const { address, globalAddressKey } = suggestion;
      try {
        const { specificAddress, localityAddress } = await separateAddress(address);
        return {
          label: specificAddress,
          subLabel: localityAddress,
          globalAddressKey,
          address,
        };
      } catch (error) {
        return {
          label: address,
          subLabel: '',
          globalAddressKey,
        };
      }
    });
    const mappedAddressSuggestions = await Promise.all(suggestionsParsedByGMapPromises);
    return mappedAddressSuggestions;
  };

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setFormProcessing(true);
        const productContainer = document.getElementById('product-result');

        const res = await getQueryParams();

        if (res.isError) {
          setFormProcessing(false);
          return setError({ isError: true, message: res.message });
        }
        router.push(res.param);
        // This component is used in both hero-banner and product-lookup page
        // The product lookup page has this product container and the hero-banner page does not
        // Hero-banner page will eventually redirect to this product-lookup page
        // So we need to check if the product container exists before scrolling
        // Also we don't want the formProcessing stops before the router redirect is done
        if (productContainer) {
          productContainer.scrollIntoView({ behavior: 'smooth' });
          setFormProcessing(false);
        }
      }}
    >
      <Flex className={wrapper}>
        <Text className={textBox} color="contrast" style="body2" type="text" weight="bold">
          Search for your address to get started
        </Text>
        <AddressSearchBox
          addressSearch={addressSearch}
          error={error.isError}
          errorMessage={error.message}
          onClearClicked={clearErrors}
          onInputValueChange={(address) => {
            clearErrors();
            setAddress(address);
          }}
          processSelectedItem={async (address) => {
            const res = await processSelectedItem(address);
            if (res.isError) return setError({ isError: true, message: res.message });
            setDpidFromDropdwon(res.dpid);
            setAddressObj(res.address);
            setIsBusiness(
              res.delivery_type?.toLowerCase() == 'mixed' || res.delivery_type?.toLowerCase() == 'business'
            );
          }}
        />
        <PromoInput
          clearErrors={clearErrors}
          home
          isChecked={isChecked}
          onClearValue={onClearValue}
          promo={promo ? promo : ''}
          ref={inputPromoRef}
          setCheckedInParent={setIsChecked}
        />
        {error && error.message && (
          <Flex className={errorBox}>
            <Icon className={errorColor} name="warning" />
            <Flex flexDirection="column">
              <Text className={errorColor} color="secondary" style="body2">
                {error.message}
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex align="flex-start" alignItems="center" direction="row" gap="space-b">
          <Button
            className={clsx(buttonSpacing, home && duskTheme)}
            disabled={!!(formProcessing || error.isError)}
            label={formProcessing ? 'Loading plans' : 'See Plan'}
          />
        </Flex>
      </Flex>
    </form>
  );
};
