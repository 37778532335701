import { checkProximity } from '@solstice/ui/src/components/blocks/pipeline-locator/helpers';
import { BUSINESS, PERSONAL } from 'packages/constants/src';

import type { PlanType } from 'packages/constants/src';
import type { PipelineLocator as PipelineLocatorT } from 'packages/types/src';

const localityTypes = ['locality', 'administrative_area_level_1', 'postal_code'];
const ignoredTypes = ['administrative_area_level_2', 'country'];

type AddressComponent = {
  specificAddress: string;
  localityAddress: string;
  location: google.maps.LatLng | undefined;
};

const separateAddress = (address: string): Promise<AddressComponent> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const addressComponents = results[0].address_components;
        const specificAddressComponents = addressComponents.filter((component) => {
          const types = component.types;
          return (
            !types.some((type) => localityTypes.includes(type)) && !types.some((type) => ignoredTypes.includes(type))
          );
        });
        const localityAddressComponents = addressComponents.filter((component) => {
          const types = component.types;
          return types.some((type) => localityTypes.includes(type));
        });

        const specificAddress = specificAddressComponents.map((component) => component.long_name).join(' ');
        const localityAddress = localityAddressComponents.map((component) => component.short_name).join(', ');

        const location = results[0].geometry?.location;
        resolve({ specificAddress, localityAddress, location });
      } else {
        reject('unable to geocode address');
      }
    });
  });
};

const checkBusinessOrPersonal = (
  location: google.maps.LatLng | undefined,
  allLines: PipelineLocatorT.Lines,
  proximity: number
): PlanType => {
  if (!location) return BUSINESS;
  return checkProximity(location, allLines, proximity) ? PERSONAL : BUSINESS;
};

export { separateAddress, checkBusinessOrPersonal };
